/* requries:
jquery-3.2.1.min.js
*/

$(document).ready(function(){

	init = function () {
      countdown ();
      smoothScroll ();
      // popUpVideo ();
      fadeIn ();
      mobileNavigation();
      addToCalendarPopup();
      showBioSingle ();
      showBioAdvocate ();
      detectScroll ($('.program'), $('.background.slide-up'), 0);
      detectScroll ($('.share'), $('.column-right.slide-in'), 500);
      detectScroll ($('.join-us'), $('.join-us'), -100);
      animatePath ($('.join-us path'));
      detectTitleScroll ();
      // bioScroll ()
	}


  function randomNumber (max, min) {
    return Math.random() * (max - min) + min;
  }

  function animatePath (paths) {
    if (paths.length > 0) {
     
      var timeout = 125;
      setTimeout(function () {
          var targetNum = 0;
          var targetElem = paths[0];
          var num = randomNumber(100, -100);
          $(paths[targetNum]).css('transform', 'translateY(' + num + 'px');
          paths.splice(targetNum, 1);

          animatePath (paths);

          setTimeout(function () {
            $(targetElem).css('transform', 'none')
          }, 1000);
          
      }, timeout);
    } else {
      $('path').each (function () {
        setTimeout(function () {
          $('circle').css('opacity', 1)
          $('#circle-5').css('opacity', 1)
        }, 1400)
      })
    }
  }

  function showBioSingle () {
    $('.single .winner .more').click (function (e) {
      // revert anything that was already open to closed state
      closeBios ();
      $('.selected-winner .winner').slideUp().remove();
      $('.advocates .winner').show ();

      var winnerElement = $(this).parents('.winner');
      var winnerSection = $(this).parents('.winner-group');

      // open this one
      $(this).next('.more-info').slideDown();
      $(this).hide ();
      $(this).parents('.winner').toggleClass('has-border');
      winnerSection.find($('.close-winner')).show ()

      // close click event
      $('.close-winner').click (function (e) {
        closeBios ();
      })
    })
  }

  function addToCalendarPopup () {
    $('.add-to-calendar').on('click', function() {
        $('#calendar-options').toggleClass('visible', true);
        $('body').toggleClass('frozen', true);
    });

    $('#calendar-options').on('click', function() {
        $('#calendar-options').toggleClass('visible',false);
        $('body').toggleClass('frozen', false);
    });
  }

  function showBioAdvocate () {
    $('.advocates .winner .more').click (function () {

      // empty the winner container, show all winners
      $('.selected-winner .winner').remove();
      $('.advocates .winner').show ();

      closeBios ();

      var winnerSection = $(this).parents('.winner-group');
      var winnerElement = $(this).parents('.winner');
      var selectedWinner = winnerSection.find('.selected-winner');

      winnerElement.clone().appendTo(winnerSection.find('.selected-winner'));
      winnerElement.hide ();
      winnerSection.find($('.close-winner')).show ()
      
      openBio ($('.selected-winner .winner'));
      var section = winnerElement.parents('.winner-group').find('h5');

      $('html, body').animate({
          scrollTop: section.offset().top - 125
      }, 600);

      // close click event
      $('.close-winner').click (function (e) {
        winnerElement.show ();
        $('.selected-winner .winner').slideUp().remove();
        closeBios ();

      })
    })
  }

  function openBio (winnerElement) {
    winnerElement.find('.more-info').show();
    winnerElement.find('.more').hide();
    winnerElement.toggleClass('has-border');
    winnerElement.prev('.close-winner').show();
  }

  function closeBios () {
    $('.more-info').hide ();
    $('.winner .more').show ();
    $('.winner.has-border').removeClass('has-border');
    $('.close-winner').hide ();
  }

  function fadeIn () {
    setTimeout (function () {
      $('.fade-in-photo.video').addClass('visible');
    }, 800);
    setTimeout (function () {
      $('.fade-in-photo.award').addClass('visible');
    }, 1000);
    setTimeout (function () {
      $('.fade-in-photo.teen').addClass('visible');
    }, 1200);
  }

  function detectTitleScroll () {
    window.addEventListener ("scroll", scrollFunction, {passive: true})
  }

  function scrollFunction () {
    var sectionOffset = $('.program').offset().top;
      var extraOffset = $(window).height() * .75;
      var topOfWindow = $(window).scrollTop();
      if (sectionOffset < topOfWindow + extraOffset) {
        animatePath($('.program path'));
        window.removeEventListener("scroll", scrollFunction);
      } 
  }

  function detectScroll (triggerSection, element, extraOffset) {
    $(window).scroll (function () {

      element.each (function () {
        var sectionOffset = triggerSection.offset().top;
        var topOfWindow = $(window).scrollTop();

        if (sectionOffset < topOfWindow + extraOffset) {
          element.addClass('visible');
        }
      })
    })
  }

  function countdown () {
    var countdownTo = new Date('September 23, 2020 0:00:00-04:00')
    var countdownDay = countdownTo.getDate();
    var countDownDate = countdownTo.getTime();

    var now = new Date();
    console.log(now.getDate());
    console.log(now.getMonth());

    // var distance = countDownDate - now.getTime ();

    // if the event is tomorrow, just show 01 Day
    if (now.getDate() === 22 && now.getMonth() === 8) {
      showCountdown('01', 'Day');

    // if it's more than a day away, don't recalculate constantly
    // } else if (now.getDate() < countdownDay && now.getMonth() === 8) {
    //   var add1 = Math.floor(distance / (1000 * 60 * 60 * 24)) + 1;
    //   var days = addLeadingZero(add1);
    //   showCountdown (days, 'Days');
    
    // if it's the day of ...
    } else {
      // update every 1 second by:
      var update = setInterval(function() {

        // counting down to the exact hour the event starts
        countDownDate = new Date('September 23, 2020 18:00:00-04:00').getTime();
        // recalculating the time diff
        var distance = countDownDate - new Date().getTime();
        // calculating hours/minutes/seconds
        var hours = addLeadingZero(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        var minutes = addLeadingZero(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        var seconds = addLeadingZero(Math.floor((distance % (1000 * 60)) / 1000));

        // and showing whatever metric is appriate
        if (hours > 0) {
          $('.countdown .number').removeClass('few-digits');
          showCountdown (hours + ':' + minutes, 'Hours');
        } else if (minutes > 0) {
          $('.countdown .number').removeClass('few-digits');
          showCountdown (minutes + ':' + seconds, 'Minutes');
        } else if (seconds > 0) {
          showCountdown (seconds, 'Seconds');
        }
        // when over, stop repeating this function and and stop counting down
        if (distance < 0) {
          clearInterval(update);
          $('.countdown .number').empty();
          $('.countdown .interval').css('font-size', '.9em')
          $('.countdown .interval').text("The show will begin momentarily");
        }
      }, 1000);
    }
  }

  function addLeadingZero (num) {
    if (num.toString().length === 1) {
      return '0' + num;
    } else {
      return num;
    }
  }

  function showCountdown (number, interval) {
    $('.number').text(number);
    $('.interval').text(interval);
  }

  function smoothScroll () {   
    $(document).on('click', 'a[href^="#"]', function (event) {
      event.preventDefault();
      var lengthScrolled = window.pageYOffset;

      // if this is a bio link, prevent the page from scrolling down/up the page (happens bc this function automatically closes other bios)
      if ($(this).hasClass('bio-link')) {
        console.log ('bio')
        $('html, body').animate({
            scrollTop: lengthScrolled
        }, 500); 
      // if this is a menu link, just scroll to that place
      } else {
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - $('header').height()
        }, 500); 
      }

    });
  }

  // function popUpVideo () {
  //   $('.phase3 .popUpVideo').click ( function (e) {
  //     $('section.join-us').hide ();
  //     $('.video-holder.unscrollable').fadeIn();

  //     var frame = $('iframe.video-popup');

  //     $('.close').click (function (e) {
  //       // fade out video, allow screen to be scrolled
  //       $('.video-holder').fadeOut();
  //       // $('.overlay').removeClass('shown');
  //       // $('body').removeClass('frozen');

  //       var vidsrc = frame.attr('src'); // save url of video
  //       frame.attr('src',''); // set it to nothing, video will stop
  //       frame.attr('src', vidsrc); // set back to original for the next time you open it
  //       // $('.video-popup').stopVideo();
  //     })
  //   })
  // }


  function mobileNavigation () {
    $('#hamburger').on('click', function() {
        $('#menu').toggleClass('open');
        $('#calendar-options').toggleClass('visible',false);

        if ( $('#menu').hasClass('open') ) {
          $('body').toggleClass('frozen', true);
        } else {
          $('body').toggleClass('frozen', false);
        }
        $('.menu-item').click (function () {
          $('body').toggleClass('frozen', false);
        })
    });

    $('.menu-item').each(function(){
      $(this).on('click', function() {
        $('#menu').toggleClass('open',false);
      });
    });
  }

	init();
});




